const BibleApps = {
  title: 'Bible Apps & Websites',
  picture: 'https://www.christianbytes.com/bible-apps.jpg',
  armoryCallout: "Read God's Word",
  id: 'bible-apps',
  backgroundColor: '#5b3d91',
  link: 'bible-apps-sites',
  description:
    'Reading the Word of God is a great daily habit. Thanks to technology, we can easily read the Word anywhere in the World we are! Here are some great Bible apps & sites for your Bible study.',
  linkToText: 'View the Bible apps & sites recommendations',
  intro:
    "Here are some ways that you can read & study your Bible. I'm currently in the process of reviewing a couple Bible apps, so stay tuned. ",
  items: [
    {
      text: 'OliveTree Bible Software',
      link: 'https://www.olivetree.com',
      subtitle: 'iOS/Android Apps, Mac and Windows',
      picture: 'https://www.christianbytes.com/olive-tree-bible-software.jpg',
      description: "This is currently my go-to digital Bible app. I think it does the best job of getting out of your way while bringing in multiple-resource ability to studying. It's free to get going."
    },
    {
      text: 'Logos Bible Software',
      link: 'https://www.logos.com',
      subtitle: 'iOS/Android Apps, Mac and Windows',
      picture: 'https://www.christianbytes.com/logos-bible-software.jpg',
      description: "This is a free app to get started. If you're going to prepare sermons or do some very in-depth study, this app might suit you well."
    },
    {
      text: 'Bible Gateway',
      link: 'https://www.biblegateway.com',
      subtitle: 'Browser-Base Bible',
      picture: 'https://www.christianbytes.com/bible-gateway.jpg',
      description: "This is a great site for quickly looking up verses in multiple translations. I use it fairly often when I'm on the computer and have a browser open."
    },
    {
      text: 'YouVersion',
      link: 'https://www.christianbytes.com/apps-desktop-software/youversion-bible-app-review',
      subtitle: 'iOS/Android Bible Apps',
      picture: 'https://www.christianbytes.com/static/7a0a888593256b100ed945ba15dce16e/063bf/christianbytes-youversion-bible-app-review-reading-plans-ipad.webp',
      description: "This is a good free Bible app. I did a complete review for this app. Just click the title link to read it."
    },
  ],
};

export default BibleApps;
