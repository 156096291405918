const ChristianRadioApps = {
  title: 'Christian Radio Apps & Listen Live Sites',
  armoryCallout: 'Listen to God All Day Long',
  id: 'christian-radio-apps',
  picture: 'https://www.christianbytes.com/christian-radio.jpg',
  backgroundColor: '#b5397a',
  link: 'christian-radio',
  description:
    'No matter where you are, you can listen to Christian radio thanks to many sites that offer apps and direct listen ability. In this section, are some apps and sites that I use.',
  linkToText: 'View the Christian radio recommendations',
  intro:
    'Listen to Christian music and teachings on your phone or browser. Here are some good Christian radio stations.',
  items: [
    {
      text: 'KWAVE 107.9fm',
      subtitle: 'iOS/Android Apps & Listen on Site',
      picture: 'https://www.christianbytes.com/kwave-radio.jpg',
      description: 'KWAVE has non-stop teachings (past and present) from pastors, such as Greg Laurie, Skip Heitzig, Chuck Smith and more.',
      link: 'https://www.kwve.com',
    },
    {
      text: 'CSN Radio',
      subtitle: 'iOS/Android Apps & Listen on Site',
      picture: 'https://www.christianbytes.com/csn-radio.jpg',
      description: "CSN is home to a program I like a lot: Every Man an Answer. It's a great Q & A program and I encourage you to check it out.",
      link: 'https://csnradio.com',
    },
    {
      text: 'SOS Radio',
      subtitle: 'iOS/Android Apps & Listen on Site',
      picture: 'https://www.christianbytes.com/sos-radio.jpg',
      description: "A solid Christian radio station with lots of music and some teachings. On their site, you can chose the radio station you want (10 stations: Las Vegas, Key West, etc.).",
      link: 'https://www.sosradio.net',
    },
    {
      text: 'Air1 Radio',
      subtitle: 'iOS/Android Apps & Listen on Site',
      picture: 'https://www.christianbytes.com/air1-radio.jpg',
      link: 'https://www.air1.com',
      description: "If you're in the mood for just Christian Music, this is a good station for you. There are inspirational messages sprinkled in as well."
    },
    {
      text: 'CBN Radio',
      subtitle: '12 Stations, iOS/Android Apps & Listen on Site',
      picture: 'https://www.christianbytes.com/cbn-radio.jpg',
      description: "With 12 stations to chose from, there's pretty much a mix for whatever you're after. I only listen to CBN Radio from time to time as I'm usually cycling through the other stations, but this is a nice option.",
      link: 'https://www1.cbn.com/radio',
    },
  ],
};

export default ChristianRadioApps;
