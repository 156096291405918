const Twitter = {
  title: 'Christian Twitter Accounts',
  armoryCallout: 'Faith-Based Twitter Reading',
  picture: 'https://www.christianbytes.com/christian-twitter.jpg',
  id: 'twitter-christian-accounts',
  backgroundColor: '#1da1f2',
  link: 'christian-twitter-accounts',
  description:
    "Even if you never Tweet, Twitter can be a great resource for inspiration, news and the Word of God. It's all about knowing what accounts to follow and removing the noise. In this section, I'll describe how I use Twitter and provide the Faith-based accounts I follow for a great Twitter experience.",
  linkToText: 'View the Twitter account recommendations',
  secondaryText: "Disclaimer: I'm not active on social media, so while I check these Twitter accounts from time to time, it's only for the purpose of making sure they are valid.",
  intro:
    "Here's a healthy list of Twitter handles that I follow. With this list, you'll have a healthy dose of Jesus Christ in your Twitter feed.",
  items: [
    {
      text: '@greglaurie',
      link: 'https://twitter.com/greglaurie',
      picture: 'https://www.christianbytes.com/pastor-greg-laurie.jpg',
      subtitle: 'Pastor Greg Laurie - Harvest Church',
      description: "Pastor and Evangelist :: Knowing God and Making Him Known :: Maintained by Pastor Greg and the @harvestorg team",
    },
    {
      text: '@OliveTreeMin',
      link: 'https://twitter.com/OliveTreeMin',
      picture: 'https://www.christianbytes.com/jan-markell.jpg',
      subtitle: 'Jan Markell - Olive Tree Ministries',
      description: "International radio host, author & speaker; Understanding the Times radio, heard on 850+ stations across America; Founder & Director Olive Tree Ministries.",
    },
    {
      text: '@PureFlix',
      link: 'https://twitter.com/PureFlix',
      picture: 'https://www.christianbytes.com/pureflix-icon.jpg',
      subtitle: 'Official Twitter Account for Pure Flix Entertainment',
      description: "Entertainment that changes lives, inspires hearts & lifts spirits.",
    },
    {
      text: '@STRtweets',
      link: 'https://twitter.com/STRtweets',
      picture: 'https://www.christianbytes.com/stand-to-reason.jpg',
      subtitle: 'Stand to Reason',
      description: "Confidence for every Christian. Clear thinking for every challenge. Courage & grace for every encounter.",
    },
    {
      text: '@jdfarag',
      link: 'https://twitter.com/jdfarag',
      picture: 'https://www.christianbytes.com/jd-farag-icon.jpg',
      subtitle: 'Pastor J.D. Farag - Calvary Chapel Kaneohe',
      description: "The official Twitter page of Bible Prophecy teacher and Pastor, J.D. Farag of Calvary Chapel Kaneohe in Hawaii.",
    },
    {
      text: '@InTouchMin',
      link: 'https://twitter.com/InTouchMin',
      picture: 'https://www.christianbytes.com/in-touch-ministries.jpg',
      subtitle: 'Pastor Charles Stanley - In Touch Ministries',
      description: "Join us as we dive into Christ’s Sermon on the Mount: http://intouch.org/blessed.",
    },
    {
      text: '@TheBabylonBee',
      link: 'https://twitter.com/TheBabylonBee',
      picture: 'https://www.christianbytes.com/babylon-bee.jpg',
      subtitle: 'Fake News You Can Trust! (News Satire Site)',
      description: "Fake news you can trust.",
    },
    {
      text: '@skipheitzig',
      link: 'https://twitter.com/skipheitzig',
      picture: 'https://www.christianbytes.com/pastor-skip-heitzig.jpg',
      subtitle: 'Pastor Skip Heitzig - Calvary Chapel Albuquerque, NM',
      description: "Christian, Husband, Dad, Grandpa, Pastor, Author, Photographer, Musician, Motorcycle Enthusiast.",
    },
    {
      text: '@GodsNotDeadFilm',
      link: 'https://twitter.com/GodsNotDeadFilm',
      picture: 'https://www.christianbytes.com/gods-not-dead-2-icon.jpg',
      subtitle: "Official Twitter Account for the Movie God's Not Dead 2",
      description: "God's Not Dead 2 Now Streaming on http://PureFlix.com",
    },
    {
      text: '@PriscillaShirer',
      link: 'https://twitter.com/PriscillaShirer',
      picture: 'https://www.christianbytes.com/priscilla-shirer.jpg',
      subtitle: 'Just a girl...with a Sword',
      description: "Just a girl....with a Sword",
    },
    {
      text: '@harvestorg',
      link: 'https://twitter.com/harvestorg',
      picture: 'https://www.christianbytes.com/harvest-church-icon.jpg',
      subtitle: 'Harvest Church (Riverside)',
      description: "Harvest Christian Fellowship w/ Pastor @GregLaurie • Knowing God and Making Him Known",
    },
    {
      text: '@BeholdIsrael',
      link: 'https://twitter.com/BeholdIsrael',
      picture: 'https://www.christianbytes.com/amir-tsarfati.jpg',
      subtitle: 'Amir Tsarfati - Behold Israel',
      description: "Middle-East Affairs Expert, Bible Teacher. Founder & President of Behold Israel.",
    },
    {
      text: '@ChristianCinema',
      link: 'https://twitter.com/ChristianCinema',
      picture: 'https://www.christianbytes.com/christian-cinema.jpg',
      subtitle: 'Official Account for Christian Cinema',
      description: "Since 1999 the leader in Christian movies: Digital HD, reviews, news. ROKU, FireTV, AppleTV.",
    },
    {
      text: '@CBNOnline',
      link: 'https://twitter.com/CBNOnline',
      picture: 'https://www.christianbytes.com/cbn.jpg',
      subtitle: 'Main Twitter for CBN Online',
      description: "Encouraging scriptures, daily devotions, personal testimonies & stories from around the world!",
    },
    {
      text: '@CBNNews',
      link: 'https://twitter.com/CBNNews',
      picture: 'https://www.christianbytes.com/cbn-news.jpg',
      subtitle: 'Twitter Account for CBN News',
      description: "Christian Broadcasting Network, bringing International Christian Inspired 24-hour News.",
    },
    {
      text: '@KLOVERadio',
      link: 'https://twitter.com/KLOVERadio',
      picture: 'https://www.christianbytes.com/k-love-radio.jpg',
      subtitle: 'Account for the K-Love Christian Music Radio Station',
      description: "Positive & encouraging music that inspires meaningful relationships with Christ.",
    },
    {
      text: '@KLOVEnews',
      link: 'https://twitter.com/KLOVEnews',
      picture: 'https://www.christianbytes.com/k-love-radio-news.jpg',
      subtitle: 'Account for the News Section of K-Love Radio',
      description: "Thanks for stopping by for news + useful, encouraging and faith-based stories.",
    },
    {
      text: '@ACLJ',
      link: 'https://twitter.com/ACLJ',
      picture: 'https://www.christianbytes.com/aclj.jpg',
      subtitle: 'Constitutional Law Firm - Jay Sekulow',
      description: "The ACLJ is a constitutional law firm, led by @JaySekulow committed to defending religious liberty, free speech, and the right to life in America & beyond.",
    },
    {
      text: '@raul_ries',
      link: 'https://twitter.com/raul_ries',
      picture: 'https://www.christianbytes.com/somebody-loves-you.jpg',
      subtitle: 'Pastor Raul Ries - Calvary Chapel Golden Springs',
      description: "Raul Ries is the Pastor of Calvary Chapel Golden Springs and President of @slyworldwide",
    },
    {
      text: '@KWVE (Radio)',
      link: 'https://twitter.com/KWVE',
      picture: 'https://www.christianbytes.com/kwave-radio-icon.jpg',
      subtitle: 'KWAVE Radio - Music & Teachings',
      description: "Your home for worship & the word 107.9 FM - We are a Christian, Bible teaching station. Listen to us online at http://kwave.com or on our app!",
    },
    {
      text: '@biblegateway',
      link: 'https://twitter.com/biblegateway',
      picture: 'https://www.christianbytes.com/bible-gateway-icon.jpg',
      subtitle: 'Official Twitter Account for BibleGateway.com',
      description: "Most used Christian site. Read, search, study, compare, & share the Bible in 200+versions & 70+languages. Free apps. Devotionals. Follow for Bible verses & news.",
    },
    {
      text: '@air1radio',
      link: 'https://twitter.com/air1radio',
      picture: 'https://www.christianbytes.com/air1-radio-icon.jpg',
      subtitle: 'Official Twitter Account for Air1 Christian Music Radio',
      description: "Air1 is THE worship station. A better mix of music with a dose of feel good!",
    },
    {
      text: '@AllianceDefends',
      link: 'https://twitter.com/AllianceDefends',
      picture: 'https://www.christianbytes.com/alliance-defends.jpg',
      subtitle: 'Alliance Defending Freedom',
      description: "Alliance Defending Freedom is a non-profit legal organization that advocates for the right of people to freely live out their faith.",
    },
  ],
};

export default Twitter;
