const SpotifyChristianPlaylists = {
  title: 'Christian Spotify Playlists',
  armoryCallout: 'Tons of Hours of Christian Music',
  picture: 'https://www.christianbytes.com/christian-streaming-music.jpg',
  id: 'spotify-christian-playlists',
  backgroundColor: '#1DB954',
  link: 'christian-spotify-playlists',
  description:
    'Do you use Spotify (or planning to)? Fill up your life with great Christian music by adding these playlists to your Spotify account. One of my recommendations has 57 hours of Christian music!',
  linkToText: 'View the Christian playlists on Spotify I recommendation',
  intro:
    "If you use Spotify, here are some playlists of Christian music to check out. There's over 60 hours of Christian music to enjoy!",

  items: [
    {
      text: 'Worship Today',
      link:
        'https://open.spotify.com/playlist/1Z651YIESCTYJcLHHzrIzV?si=yuGPl4yOQgyxOs8PB_95cA',
      review: '',
      picture: 'https://www.christianbytes.com/christian-spotify-worship-today.jpg',
      subtitle: '56 Great Worship Songs',
      description: "This list gets a lot of play from me. It has 56 songs on it (at this time) that I like. A couple samples: Way Maker, Another in the Fire, Living Hope, etc."
    },
    {
      text: 'Jesus Lives',
      link:
        'https://open.spotify.com/playlist/1e1fC3m4TqapHN3DLEof3y?si=XxcM0r_QQRCwlCh73pTnFg',
      review: '',
      picture: 'https://www.christianbytes.com/christian-spotify-jesus-lives.jpg',
      subtitle: 'Worship Jesus Christ',
      description: "This list is a work in progress for me. I'm working to build a huge list of Christian music that actually says the name of Jesus or God the Father. I'm not a big fan of Christian music that could be mistaken (or used) on a secular radio station."
    },
    {
      text: 'Contemporary Christian Essentials',
      link:
        'https://open.spotify.com/playlist/6BLwR2m7XNuiBgNmMgBCSr?si=MalomxKGQe-2C7nHY2eELg',
      review: '',
      picture: 'https://www.christianbytes.com/christian-spotify-contemporary-christian-essentials.jpg',
      subtitle: '97 Contemporary Christian Songs',
      description: "This playlist was originally an Apple Music playlist. I just copied it over to Spotify to enjoy. There's a mix of music here, from Newsboys to Toby Mac."
    },
    {
      text: 'New Music Friday Christian',
      link:
        'https://open.spotify.com/playlist/37i9dQZF1DWVtgG63SDdt8?si=xpxIns1VTySKMss5O4qEeg',
      review: '',
      picture: 'https://www.christianbytes.com/christian-spotify-new-music-friday-christian.jpg',
      subtitle: 'New Music Weekly - Spotify',
      description: "This is an official Spotify playlist. They update it weekly and it includes what they consider to be the best new songs in recent weeks. I listen to this list from time to time to see what's new."
    },
    {
      text: 'KWAVE 107.9 Huge Playlist',
      link:
        'https://open.spotify.com/playlist/6gcPkKg7IBKwStyVRhT3AP?si=in8mlQl5Q9etEnYSIChNEw',
      picture: 'https://www.christianbytes.com/christian-spotify-kwave.jpg',
      subtitle: '57 Hours of Christian Music',
      description: "This Spotify playlist is absolutely huge. It's from KWAVE and has all the songs they play on the radio. I listen to this list fairly often. As you might expect with such a huge list, there are many types of Christian music on here. For me, it's a little hit and miss, but unlike on the radio, you can just move song to song with ease."

    },
  ],
};

export default SpotifyChristianPlaylists;
