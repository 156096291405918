const Podcasts = {
  title: 'Christian Podcasts',
  picture: 'https://www.christianbytes.com/christian-podcasts.jpg',
  armoryCallout: 'Take God on the Go',
  id: 'christian-podcasts',
  backgroundColor: '#e2a400',
  link: 'christian-podcasts',
  description:
    "I love listening to Podcasts. There's so much good content via podcasts right now. Of course, there's a lot of noise too. Here are some that I think are really good.",
  linkToText: 'View the Christian podcast recommendations',
  intro:
    'I love listening to Podcasts. Here are some great Christian Podcasts that will inform you, strengthen your walk in Christ and entertain you.',

  items: [
    {
      text: 'To Every Man an Answer',
      link: 'https://podcasts.apple.com/us/podcast/to-every-man-an-answer/id379095708',
      subtitle: 'Weekday Podcast',
      description: "I try to listen to most episodes of To Every Man an Answer (only so much time in a day). It's a great Q & A podcast. I highly recommend it.",
      picture: 'https://www.christianbytes.com/podcast-to-every-man-an-answer.jpg'
    },
    {
      text: 'Calvary Chapel Las Vegas',
      link: 'https://podcasts.apple.com/us/podcast/calvary-chapel-las-vegas/id412797431',
      subtitle: 'Multiple Episodes Weekly',
      description: "This is another great way to listen to the messages from CCLV. I tend to watch via the app, but this is a great audio-only way to hear God's Word.",
      picture: 'https://www.christianbytes.com/cclv-youtube.jpg'
    },
    {
      text: 'Daily Radio Program with Charles Stanley - In Touch Ministries',
      link: 'https://podcasts.apple.com/us/podcast/daily-radio-program-charles-stanley-in-touch-ministries/id117752146',
      subtitle: 'Multiple Episodes Weekly',
      description: "Over the years I've listened to a fair amount of messages from Pastor Charles Stanley. This podcast is a good one to have in your list. I should point out that he also has a TV version of the podcast too.",
      picture: 'https://www.christianbytes.com/in-touch-ministries.jpg'
    },

    //
    // {
    //   text: 'A New Beginning with Greg Laurie',
    //   link: '',
    // },
    //
    // {
    //   text: "Harvest Men's Bible Fellowship",
    //   link: '',
    // },
    {
      text: "It's Time - Practical Application Bible Studies",
      link: 'https://podcasts.apple.com/us/podcast/its-time-practical-application-bible-studies/id379162034',
      subtitle: 'Bible Study Podcast',
      description: "I started listening to Pastor Mike Kestler's for his series on Revelation. It's Time!",
      picture: 'https://www.christianbytes.com/podcast-its-time.jpg'
    },
    // {
    //   text: 'Connect with Skip Heitzig Podcast',
    //   link: '',
    // },
    {
      text: 'The Pure Flix Podcast',
      link: 'https://podcasts.apple.com/us/podcast/the-pure-flix-podcast/id1452169421',
      subtitle: 'Weekly Podcast',
      description: "This is a weekly podcast from Pure Flix entertainment that offers up interviews with pastors, Christian actors, etc. I recommend listening to the January 27, 2020 episode: Inside the True Horrors of Christian Persecution.",
      picture: 'https://www.christianbytes.com/pureflix.jpg'
    },
    // {
    //   text: "Scott Herold's SOS Radio Podcast",
    //   link: '',
    // },
  ],
};

export default Podcasts;
