const ChristianMediaServices = {
  title: 'Christian TV & Movie Services',
  armoryCallout: 'Godly TV Time',
  picture: 'https://www.christianbytes.com/encounter-season-two-trailer.jpg',
  id: 'christian-tv-movie-services',
  backgroundColor: '#00aeef',
  link: 'christian-tv-movies-services',
  description:
    "Looking to replace Netflix? Want to put your money to a more Godly purpose while enjoying Godly & family-friendly content? Look no further than this section. I left Netflix, Apple TV, Disney+, etc behind. Here's what services I use.",
  linkToText: 'View the Christian TV & Movie services recommendations',
  intro:
    "Feel great about watching TV/Movies by watching things that glorify God & support companies that don't glorify sinning.",
  items: [
    {
      text: 'Pure Flix',
      link: 'https://www.pureflix.com',
      subtitle: 'Great Netflix Alternative',
      picture: 'https://www.christianbytes.com/pureflix.jpg',
      description: "I really love Pure Flix. To me it's a great Netflix alternative. There are lots of TV & movies to enjoy. Sadly they were sold to Sony in 2021. As of now the service is still growing. While I wish we could get away from large non-Christian organizations, I do hope the service grows in a Godly way. Some of the great content include: The Encounter series, Sons of Thunder series, Encounter movies, The Chosen, the God's Not Dead movies and so much more."
    }
    ],
};

export default ChristianMediaServices;
