const YouTube = {
  title: 'Christian YouTube Channels',
  armoryCallout: 'Watch Godly Videos',
  picture: 'https://www.christianbytes.com/cclv-pastor-derek-youtube.jpg',
  id: 'youtube-christian-channels',
  backgroundColor: '#c8374b',
  link: 'christian-youtube-channels',
  description:
    "I use YouTube a lot. It's a great service if you know what you're looking for (so much junk on there too). In this section, I provide links to the Christian YouTube channels that I watch.",
  linkToText: 'View the Christian YouTube channel recommendations',
  intro:
    "YouTube has a lot of Christian teaching, education & entertainment. Check out these channels on YouTube.",
  secondaryText: "I watch a lot of Christian videos on YouTube and actually pay $10 a month for YouTube Premium so I don't get ads. While I'd prefer not to pay the money, getting ads and interruptions is far worse in my opinion.",
  items: [
    {
      text: 'Calvary Chapel Las Vegas',
      link: 'https://www.youtube.com/channel/UCSunTqQt40pCVBrVdo4BXkQ',
      subtitle: 'Great Teachings from CCLV',
      picture: 'https://www.christianbytes.com/cclv-youtube.jpg',
      description:
        "I've attended CCLV over the decades and always loved the verse-by-verse teaching. When I moved away I used their app to watch messages, but this YouTube channel has that content as well. One of the things on this channel that I love are the daily devotionals from Pastor Derek.",
    },
    {
      text: 'Real Life with Jack Hibbs',
      link: 'https://www.youtube.com/user/RealLifeJackHibbs',
      subtitle: 'Teachings from Pastor Jack Hibbs',
      picture: 'https://www.christianbytes.com/pastor-jack-hibbs.jpg',
      description:
        "Pastor Hibbs is in very heavy rotation in my household. I like his tell-it-like-it-is messages and his willingness to attack subjects that some pastors won't. He's also a strong supporter of talking about societal/political issues in a church setting (with a Godly perspective). If you want to listen to a pastor strong in the Word and who won't dilute the message to make us falsely feel good, check out Pastor Hibbs.",
    },
    {
      text: 'Pastor J.D. Farag (Calvary Chapel Kaneohe)',
      link: 'https://www.youtube.com/channel/UCkz3m787ygph7Uvjxzngl-g',
      picture: 'https://www.christianbytes.com/jd-farag.jpg',
      subtitle: 'Calvary Chapel Kaneohu, Hawaii',
      description:
        "Pastor Farag teaches a lot about Bible prophecy. Once per week on this channel he does a Bible Prophecy Update video that is good. He also does a mid-week Bible study video. I make sure I watch them. One video I really enjoyed & recommend is <a href='https://www.youtube.com/watch?v=l1OeBtQFR4M' target='_blank'>Pre-Tribulation Rapture Proof</a>. Over time Pastor Farag has become someone I like and always make time to watch his videos. He has regular church service videos as well. If you spend some time watching him, I think you'll see how much he loves God.",
    },
    {
      text: 'Behold Israel',
      link: 'https://www.youtube.com/user/beholdisrael/videos',
      picture: 'https://www.christianbytes.com/behold-israel.jpg',
      subtitle: 'Reporting From Israel',
      description:
        'Behold Israel is led by Amir Tsarfati, who I first discovered by watching the Proximity Conference. This channel really grown over the past two years. He also has an app, If you like to keep up on Israel and want to better understand the Middle East from a Godly perspective, add this channel to your list.',
    },
    {
      text: 'Jan Markell (Olive Tree Ministries)',
      link: 'https://www.youtube.com/channel/UCi6jTFJAgL16-KknynFtlKA',
      subtitle: 'Gain an Understanding of End Times',
      picture: 'https://www.christianbytes.com/olive-tree-ministries.jpg',
      description:
        "I learned about Jan Markell's ministry through my mom and I'm glad I did. I enjoy the information she puts out. If you want to know more about the End Times, check her out. She has pastors from all over on her shows.",
    },
    {
      text: 'Pastor Greg Laurie (Harvest Church)',
      link: 'https://www.youtube.com/channel/UCp9pxqv-oMY1a6axT6mHcew',
      subtitle: 'Teachings from Pastor Greg Laurie',
      picture: 'https://www.christianbytes.com/pastor-greg-laurie.jpg',
      description:
        "This channel is one that I watch as Pastor Greg Laurie releases new content. I've listened to him off and on over the years and always enjoy his messages.",
    },
    {
      text: 'Calvary Church with Skip Heitzig',
      link: 'https://www.youtube.com/channel/UClkSJseJmbgaoFEPOAERVLw',
      picture: 'https://www.christianbytes.com/calvary-chapel-nm.jpg',
      subtitle: 'Calvary Chapel Albuquerque',
      description:
        "I haven't heard too many of Pastor Skip's messages, mainly because there's only so much time in a day, but what I've watched, I've liked.",
    },
    {
      text: 'Harvest Christian Fellowship',
      link: 'https://www.youtube.com/user/harvestcf/videos',
      picture:
        'https://www.christianbytes.com/harvest-christian-fellowship.jpg',
      subtitle: 'Sermons from Pastors at Harvest',
      description:
        "This channel features sermons from the pastors at Harvest Christian Fellowship except for Greg Laurie's messages, which appear on his channel.",
    },
    {
      text: 'To Every Man an Answer',
      link: 'https://www.youtube.com/channel/UCZzBv_F9FfZEDMZk7RNoJqg',
      picture: 'https://www.christianbytes.com/podcast-to-every-man-an-answer.jpg',
      subtitle: 'Weekday Videos',
      description: "I recently discovered that To Every Man an Answer has a YouTube channel where they show their podcast recordings. I recommend this show and if you like to watch something instead of just listening, check out their channel. If you rather just listen, head over to the podcast section of the Armory for that link.",
    },
    {
      text: 'Dave Ramsey Show',
      link: 'https://www.youtube.com/channel/UC7eBNeDW1GQf2NJQ6G6gAxw',
      picture: 'https://www.christianbytes.com/dave-ramsey.jpg',
      subtitle: 'Excellent Financial Advice',
      description:
        "I'm putting this here because God calls us to be good stewards of his blessings. Aside from being very entertaining, the core principles Ramsey puts forth are solid. I recommend watching this channel and of course going through Financial Peace at your church if they offer it. I like how he talks about how being wise with money is mostly about behavior and not some hidden secret we've yet to uncover.",
    },
  ],
};

export default YouTube;
