const ChristianNews = {
  title: 'Christian News',
  armoryCallout: 'News From a Godly Perspective',
  picture: 'https://www.christianbytes.com/HisChannel-News-Don-Stewart.jpg',
  id: 'christian-news',
  backgroundColor: '#5c585d',
  link: 'christian-news',
  description:
    "As someone who went to college for journalism, the state of news for many years has frustrated me. Most mainstream media outlets have left their ethics and honor by the roadside and now fill up the space with junk (fear, etc). There are some sources that I still use from time to time though and I've listed them here.",
  linkToText: 'View the Christian news recommendations',
  intro:
    "Looking for news that's more trustworthy & maybe even from a Biblical perspective? Check out these sources.",
  items: [
    {
      text: 'HisChannel - Breaking News Live',
      subtitle: 'Live & On-Demand for Free',
      picture: 'https://www.christianbytes.com/his-channel-breaking-news.jpg',
      description:
        'I used to watch a new show hosted by Don Stewart, but that has transitioned. HisChannel, which is available on web and iOS (Apple TV), does has some shows that deal with news (World News Briefing, etc).',
      link: 'https://hischannel.com',
    },
    {
      text: 'i24 News TV',
      subtitle: 'Broadcasting from Israel',
      picture: 'https://www.christianbytes.com/i24-news-tv.jpg',
      description:
        "I watch i24 TV as my general anytime news tv. It broadcasts from Israel and tends to talk about the region. Two regular shows I'm enjoying are Holy Land and Strictly Security. Aside from the web, there are apps for this service. i24 offers both live and shows on demand. The service is $4.99 per month, but there's usually a 30-day trial.",
      link: 'http://i24news.tv',
    },
  ],
};

export default ChristianNews;
