import BibleApps from './Armory/BibleApps';
//import ChurchApps from './Armory/ChurchApps';
import Podcasts from './Armory/Podcasts';
import ChristianRadioApps from './Armory/ChristianRadioApps';
import ChristianMediaStreaming from './Armory/ChristianMediaStreaming';
import ChristianChurchSites from './Armory/ChristianChurchSites';
import SpotifyChristianPlaylists from './Armory/SpotifyChristianPlaylists';
import YouTube from './Armory/YouTube';
import Twitter from './Armory/Twitter';
import ChristianStores from './Armory/ChristianStores';
import ChristianNews from './Armory/ChristianNews';

const ArmoryMap = {
  bibleapps: BibleApps,
  //churchapps: ChurchApps,
  podcasts: Podcasts,
  christianradioapps: ChristianRadioApps,
  christianmediastreaming: ChristianMediaStreaming,
  christianchurchsites: ChristianChurchSites,
  spotifychristianplaylists: SpotifyChristianPlaylists,
  youtube: YouTube,
  twitter: Twitter,
  christianstores: ChristianStores,
  christiannews: ChristianNews,
};

export default ArmoryMap;
