import React from 'react';
import { css } from '@emotion/core';
import Colors from '../../components/Colors';
import { Card } from 'react-bootstrap';
import ArmoryStyled from '../../components/Armory/ArmoryStyled';

let backgroundColor = '';

const renderNavItems = (key, index) => {
  const currentKey = 'rni-' + index;
  const url = 'https://www.christianbytes.com/armory/' + key.link;
  if (key.armoryHomeLink) return renderArmoryHomeLink(key, index);
  return (
    <a href={url} className="christian-armory-nav-link" key={currentKey}>
      [ {key.title.toUpperCase()} ]
    </a>
  );
};

const renderArmoryHomeLink = (key, index) => {
  const currentKey = 'ahlrni-' + index;
  const url = 'https://www.christianbytes.com/armory/';
  return (
    <a href={url} className="christian-armory-nav-link" key={currentKey}>
      [ {key.title.toUpperCase()} ]
    </a>
  );
};

const renderArmoryItemLink = (link, text) => {
  const linkText = text.toUpperCase();
  return link ? (
    <a
      className="christian-armory-item-link"
      href={link}
      rel="noopener noreferrer"
      target="_blank"
      css={css`
        font-size: 1.25rem;
        font-weight: bold;
        @media (max-width: 500px) {
          font-size: 1rem;
        }
      `}
    >
      {linkText}
    </a>
  ) : (
    <span>{linkText}</span>
  );
};

const renderReviewLink = review => {
  if (!review || review === '' || review === null) return <></>;
  return (
    <span
      css={css`
        margin-left: 10px;
        font-size: 0.85rem;
        color: ${Colors.secondary};
      `}
    >
      [
      <a
        css={css`
          color: ${Colors.main};
        `}
        href={review}
      >
        Read CB's Review
      </a>
      ]
    </span>
  );
};

const renderItems = (item, index) => {
  const key = 'ri-' + index;
  return (
    <ArmoryStyled.StyledLI key={key}>
      <div
        css={css`
          display: grid;
          grid-template-areas: 'picture description';
          grid-template-columns: 100px 1fr;
          grid-gap: 0.25rem 1rem;
          margin: 0 0 1.5rem;
        `}
      >
        <div
          css={css`
            grid-area: picture;
            align-self: start;
            position: relative;
            width: 100px;
            height: 100px;
            border-radius: 50px;
            -webkit-border-radius: 50px;
            -moz-border-radius: 50px;
            background-position: center;
            background-size: cover;
            background-image: url(${item.picture});
            box-shadow: 0 0 8px rgba(0, 0, 0, 0.8);
            -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.8);
            -moz-box-shadow: 0 0 8px rgba(0, 0, 0, 0.8);
          `}
        ></div>
        <div
          css={css`
            grid-area: description;
          `}
        >
          <div>{renderArmoryItemLink(item.link, item.text)}</div>
          <div
            css={css`
              color: ${backgroundColor};
              font-size: 0.8rem;
            `}
          >
            {item.subtitle}
          </div>
          <div
            css={css`
              font-size: 1rem;
              @media (max-width: 500px) {
                font-size: 0.85rem;
              }
            `}
            dangerouslySetInnerHTML={{ __html: item.description }}
          ></div>
          {renderReviewLink(item.review)}
        </div>
      </div>
    </ArmoryStyled.StyledLI>
  );
};

const renderSectionCards = (key, index) => {
  const currentKey = 'rsc-' + key.id + index;
  backgroundColor = key.backgroundColor ? key.backgroundColor : Colors.main;
  return (
    <ArmoryStyled.StyledCard id={key.id} key={currentKey}>
      <ArmoryStyled.StyledHeader
        css={css`
          background: ${backgroundColor};
        `}
      >
        {key.title}
      </ArmoryStyled.StyledHeader>
      <Card.Body
        css={css`
          @media (max-width: 500px) {
            padding: 0.25rem;
          }
        `}
      >
        <ArmoryStyled.StyledCardText>{key.intro}</ArmoryStyled.StyledCardText>
        {
          key.secondaryText ?
          <ArmoryStyled.StyledCardText>{key.secondaryText}</ArmoryStyled.StyledCardText> : ''
        }
        <ArmoryStyled.StyledUL>
          {key.items.map(function(item, index) {
            return renderItems(item, index);
          })}
        </ArmoryStyled.StyledUL>
      </Card.Body>
    </ArmoryStyled.StyledCard>
  );
};

export default {
  renderNavItems,
  renderReviewLink,
  renderItems,
  renderSectionCards,
};
