const ChristianBookStores = {
  title: 'Christian Stores',
  armoryCallout: 'Support Christian Stores',
  picture: 'https://www.christianbytes.com/wisdom-for-today-chuck-smith.jpg',
  id: 'christian-stores',
  backgroundColor: '#274f72',
  link: 'christian-stores',
  description:
    "I don't know about you, but whenever I can, I try to purchase Godly goods/services through people & companies that are serving God. To me, it's about being a good steward of what God has provided.",
  linkToText: 'View the Christian store recommendations',
  previewText: "Want to purchase Christian goods/services from places that honor God & do HIS work? For Amazon alternatives look here.",
  intro:
    "Want to purchase Christian goods/services from places that honor God & do HIS work? For Amazon alternatives look here. I've included two sources that I buy from before going elsewhere.",
  items: [
    {
      text: 'ChristianBook.com',
      link: 'https://www.christianbook.com/',
      picture: 'https://www.christianbytes.com/christianbook-store.jpg',
      subtitle: 'Books, Movies and More',
      description: "Whenever I've bought something from ChristianBook.com I'm always pleased with the prompt shipping and delivery. If you get on their email list, you'll get alerts to sales often. I find they have good information (pictures, reviews, etc) to help with purchases."
    },
    {
      text: 'LifeWay.com',
      link: 'https://www.lifeway.com/',
      picture: 'https://www.christianbytes.com/lifeway-store.jpg',
      subtitle: 'Books, Movies and More',
      description: "LifeWay is another online Christian bookstore I like. They used to have brick & mortar stores, but today it's all online. Sometimes you can find really good normal-priced deals with them. For example, I was in the market for yet another Bible and I found it here for about $20 less than CB."
    },
  ],
};

export default ChristianBookStores;
