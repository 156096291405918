import styled from '@emotion/styled';
import Colors from '../../components/Colors';
import { Card } from 'react-bootstrap';

const StyledHeader = styled(Card.Header)`
  display: block;
  font-size: 2rem;
  font-style: bold;
  color: ${Colors.light};
  text-align: center;
`;

const StyledCardText = styled(Card.Text)`
  font-size: 0.85em;
`;

const StyledCard = styled(Card)`
  width: calc(90%);
  margin: 0 auto 2rem auto;
  @media (max-width: 500px) {
    width: 98%;
  }
`;

const StyledLI = styled('li')`
  list-style: none;
`;

const StyledUL = styled('ul')`
  margin-top: 1rem;
  list-style: none;
  padding: 0;
`;

export default {
  StyledHeader,
  StyledCardText,
  StyledCard,
  StyledLI,
  StyledUL,
};
