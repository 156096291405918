const ChristianChurchSites = {
  title: 'Church / Teaching Sites & Apps',
  armoryCallout: 'Get Connected',
  picture: 'https://www.christianbytes.com/cclv-site.jpg',
  backgroundColor: '#586e5d',
  id: 'christian-church-teaching-sites',
  link: 'christian-church-teaching-sites',
  description:
    'I love that I can connect with churches online from anywhere and that I can learn & improve my walk with Christ from anywhere. No matter where you are, these recommendations will help get you plugged in.',
  linkToText: 'View the Christian church & teaching site recommendations',
  intro:
    'Here are links to some Christian churches & online courses that will help you grow in Christ.',
  items: [
    {
      text: 'Calvary Chapel Las Vegas',
      link: 'http://cclasvegas.org',
      subtitle: 'Official Site for CCLV - Pastor Derek Neider',
      description: "I really like CCLV as they go through the Bible verse by verse. They also have an app that I use somewhat often on my phone and Apple TV. I've also listed the CCLV channel in the YouTube section of the Armory.",
      picture: 'https://www.christianbytes.com/cclv-youtube.jpg'
    },
    {
      text: 'Harvest',
      link: 'https://harvest.org',
      subtitle: 'Official Site for Harvest Church, Riverside',
      description: "Harvest is a church I've checked out online. I'm not in the geographical area, but I will use their app from time to time. I tend to watch Pastor Greg Laurie's YouTube channel more than the app. And I've listened to Pastor Laurie's messages here and there over the years.",
      picture: 'https://www.christianbytes.com/harvest-christian-fellowship.jpg'
    },
    {
      text: 'Connect with Pastor Skip Heitzig',
      link: 'http://connectwithskip.com',
      subtitle: 'Resource Site for Pastor Skip Heitzig',
      description: "This site has a lot of information about Pastor Skip (podcast, videos, etc). Generally, I listen on YouTube.",
      picture: 'https://www.christianbytes.com/connect-skip-heitzig.jpg'
    },
    {
      text: 'Real Life with Jack Hibbs',
      link: 'https://reallifewithjackhibbs.org',
      subtitle: 'Resource Site for Pastor Jack Hibbs',
      description: "Lots of stuff on this site. My wife and I watch Pastor Hibbs a lot on YouTube. His channel has many good broadcasts.",
      picture: 'https://www.christianbytes.com/pastor-jack-hibbs.jpg'
    },
    {
      text: 'How to Study Your Bible - 5 Hour Free Video Training',
      link: 'https://vimeo.com/channels/1620243',
      subtitle: 'CCLV  - Pastor Derek Neider - FREE',
      description: "I haven't been through this course yet (I learned inductive Bible study years ago though). I'm planning on going through it & writing a review, so stay tuned. Aside from going to CCLV for years, I trust Pastor Neider to deliver and that's why it's listed here. Best of all, it's free so check it out and learn how to get the most out of your Bible.",
      picture: 'https://www.christianbytes.com/htsyb-cclv-derek-neider.jpg'
    },
    {
      text: 'Tell Someone',
      link: 'https://courses.harvest.org/register/tell-someone/',
      subtitle: 'Harvest Church - Free Online Course',
      description: "The review for this course is coming at some point. I'm excited to check it out.",
      picture: 'https://www.christianbytes.com/harvest-christian-fellowship.jpg'
    },
    {
      text: 'New Believers Course',
      link: 'https://courses.harvest.org/register/new-believers-course/',
      subtitle: 'Harvest Church - Free Online Course',
      description: "Like the other Harvest listing, the review for this course is coming at some point also.",
      picture: 'https://www.christianbytes.com/harvest-christian-fellowship.jpg'
    },
  ],
};

export default ChristianChurchSites;
